<template>
  <section class="security">
    <span class="coming-soon">
      Coming soon...
    </span>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>